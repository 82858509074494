// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-bp-js": () => import("./../../../src/pages/bp.js" /* webpackChunkName: "component---src-pages-bp-js" */),
  "component---src-pages-bp-signup-js": () => import("./../../../src/pages/bp/Signup.js" /* webpackChunkName: "component---src-pages-bp-signup-js" */),
  "component---src-pages-consent-js": () => import("./../../../src/pages/consent.js" /* webpackChunkName: "component---src-pages-consent-js" */),
  "component---src-pages-consumer-js": () => import("./../../../src/pages/consumer.js" /* webpackChunkName: "component---src-pages-consumer-js" */),
  "component---src-pages-customer-js": () => import("./../../../src/pages/customer.js" /* webpackChunkName: "component---src-pages-customer-js" */),
  "component---src-pages-developer-[id]-js": () => import("./../../../src/pages/developer/[id].js" /* webpackChunkName: "component---src-pages-developer-[id]-js" */),
  "component---src-pages-developer-login-js": () => import("./../../../src/pages/developer/login.js" /* webpackChunkName: "component---src-pages-developer-login-js" */),
  "component---src-pages-ifa-js": () => import("./../../../src/pages/ifa.js" /* webpackChunkName: "component---src-pages-ifa-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-rm-login-js": () => import("./../../../src/pages/rm/login.js" /* webpackChunkName: "component---src-pages-rm-login-js" */),
  "component---src-pages-walkin-form-js": () => import("./../../../src/pages/walkin-form.js" /* webpackChunkName: "component---src-pages-walkin-form-js" */)
}

