/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
// global tailwind

import "./src/css/index.css";

export const onServiceWorkerUpdateReady = () => {
  document
    .getElementById("___gatsby")
    .setAttribute("data-update-available", "true");
};
